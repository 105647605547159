import { useState } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { saveAuth } from "src/components/Flags/flagsAuthUtil"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { spacing } from "src/ui/spacing"

export function FlagsAuth({
  onSaveAuth,
  reminder,
}: {
  onSaveAuth: () => void
  reminder: string
}) {
  const [text, setText] = useState("")
  async function handleSaveAuth() {
    if (text !== reminder) {
      return
    }
    saveAuth()
    onSaveAuth()
  }
  return (
    <BoxWrap>
      <Box>
        <TextField
          label="The first rule is"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <MButtonLegacy
          onClick={handleSaveAuth}
          disabled={text !== reminder}
          size="large"
        >
          Acknowledge
        </MButtonLegacy>
      </Box>
    </BoxWrap>
  )
}

const BoxWrap = styled.div`
  padding: ${spacing.M};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
  max-width: 65ch;
  width: 100%;
`
